import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import BackButton from "../../../components/BackButton";
export const _frontmatter = {
  "author": "Hops Wong",
  "date": "2012-05-17 UTC -8",
  "length": 1,
  "location": "Salt Lake City, UT",
  "path": "/moving-to-salt-lake-city/",
  "title": "Moving to Salt Lake City",
  "tags": ["personal", "outdoor", "lifestyle"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`After living in California for 3 years, it was very different moving out to Salt Lake City for a new job.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEDBP/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAGijqsZAP/EABsQAAICAwEAAAAAAAAAAAAAAAABAgMSExQx/9oACAEBAAEFAm1IWsWBb64RZy1H/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHhAAAgEDBQAAAAAAAAAAAAAAAAECAxEhIjEycZH/2gAIAQEABj8C1RHmxyRT7EpRTNn6f//EABoQAQADAAMAAAAAAAAAAAAAAAEAETEhQYH/2gAIAQEAAT8hotuqdgaM9uCvFUA+WPgDlmRdwj//2gAMAwEAAgADAAAAEFcf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/EMGwv//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAgEBPxDUtb//xAAbEAEAAwADAQAAAAAAAAAAAAABABEhMUFRcf/aAAgBAQABPxCg2PgAryESrZZn3ZWr1wmwzb21Gs7I2RmmlTp8jB+QVT//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Mountains",
          "title": "Mountains",
          "src": "/static/b6e8bce268fab89a6f6f777aafd96cab/8e1fc/mountains.jpg",
          "srcSet": ["/static/b6e8bce268fab89a6f6f777aafd96cab/863e1/mountains.jpg 225w", "/static/b6e8bce268fab89a6f6f777aafd96cab/20e5d/mountains.jpg 450w", "/static/b6e8bce268fab89a6f6f777aafd96cab/8e1fc/mountains.jpg 900w", "/static/b6e8bce268fab89a6f6f777aafd96cab/b768e/mountains.jpg 1350w", "/static/b6e8bce268fab89a6f6f777aafd96cab/5cbb4/mountains.jpg 1651w"],
          "sizes": "(max-width: 900px) 100vw, 900px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`I spent 3 years in Salt Lake City and it was a snowboarder's dream city.`}</p>
    <p>{`Just go out and talk to a tree. Make friends with it. Let the paint work. By now you should be quite happy about what's happening here. Just go back and put one little more happy tree in there. There we are. I guess that would be considered a UFO. A big cotton ball in the sky.`}</p>
    <p>{`Poor old tree. I thought today we would do a happy little picture. Happy painting, God bless. There comes a nice little fluffer. Now then, let's play.`}</p>
    <p>{`A little happy sunlight shining through there. If there's two big trees invariably sooner or later there's gonna be a little tree. Look at them little rascals. We might as well make some Almighty mountains today as well, what the heck.`}</p>
    <p>{`If you didn't have baby clouds, you wouldn't have big clouds. We'll play with clouds today. You don't have to spend all your time thinking about what you're doing, you just let it happen.`}</p>
    <p>{`That's a crooked tree. We'll send him to Washington. If what you're doing doesn't make you happy - you're doing the wrong thing. Everyone is going to see things differently - and that's the way it should be. If we're gonna walk though the woods, we need a little path. It just happens - whether or not you worried about it or tried to plan it.`}</p>
    <p>{`Nothing wrong with washing your brush. Volunteering your time; it pays you and your whole community fantastic dividends. There's nothing wrong with having a tree as a friend. You have to make these big decisions. Only God can make a tree - but you can paint one. This is the fun part`}</p>
    <p>{`Maybe there's a happy little bush that lives right there. I'm gonna start with a little Alizarin crimson and a touch of Prussian blue The light is your friend. Preserve it.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      